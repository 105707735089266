<div class="container1">
    <h1>Kontakt</h1>
    <h2>Telefon: 015140371778</h2>
    <h2>Email: kontakt@gersprenz-betreuung.com</h2>
  <h2>Adresse</h2>
  <p>
    Andrea Wanning <br>
    Erbacher Str. 86<br>
    64395 Brensbach
  </p>
  <p></p>
</div>

<!--<div class="container2">
  <form>
    <label for="name">Name</label>
    <input type="text" id="name" name="name" class="full-width"/><br/>
    <label for="email">Email Address</label>
    <input type="email" id="email" name="_replyto" class="full-width"/><br/>
    <label for="message">Message</label>
    <textarea name="message" id="message" cols="30" rows="10" class="full-width"></textarea><br/>
    <button mat-raised-button>
      <a class="btn1">
        <i class="material-icons">send</i>
        <span>Senden</span>
      </a>
    </button>
  </form>
</div>-->

