<section id="aboutAndCertified" class="certified-section">
  <div class="AboutUsHeadline" fxLayout="column" fxhide="false" fxLayout.gt-md="row" fxLayoutGap.gt-md="20px"
       fxLayoutAlign="center center">
    <section class="CE">
      <h2>IHR STARKER PARTENR IN SACHEN BETREEUUNG</h2>
    </section>
  </div>
  <div class="aboutUs" fxflex fxLayout="row" fxLayout.lt-sm="column"
       style="flex-direction: row; box-sizing: border-box; display: flex; flex: 1 1 0%;">


    <div class="aboutUsElement1" fxflex style="flex: 1 1 0%; box-sizing: border-box;">
      <p style="text-align: left">Oft genügt schon ein kleiner Funke! 2000 hatten wir die zündende Idee unser Hobby
        „PCs“ zum Beruf zu machen und
        legten damit den Grundstein für unser Unternehmen – dem SYSTEMHAUS FRANKFURT.</p>
      <p style="text-align: left">Aus dem Funke wurde rasch ein Feuer – Begeisterung steckt eben an. Und dank dem
        Zuwachs an technikbegeistertern Mitarbeitern und einem
        starken Netzwerk aus kompetenten und zuverlässigen Partnern sind wir nunmehr seit über 15 Jahren
        branchenübergreifender
        Ansprechpartner rund um Telekommunikation- und IT-Services.
        Am Standort Frankfurt stehen wir unseren Kunden vor allem im Rhein-Main-Gebiet und darüber hinaus auch in ganz
        Deutschland mit Rat und Tat zur Seite</p>

    </div>

    <div class="aboutUsElement2" fxflex style="flex: 1 1 0%; box-sizing: border-box;">
      <p style="text-align: left">Ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
        quistrty nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea comm consequ. Duis autem
        vel eum iriure dolor in hendrerit
        in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et
        accumsan et iusto odi dignissim qui blandit
        praesent luptatum zzril delenit augue duis ddolore magna volutpat.</p>
    </div>
  </div>
</section>
