<div class="container1">
  <h1>Zuhause ist es doch am schönsten </h1>
  <p>Viele Menschen mit Einschränkungen der allgemeinen Alltagskompetenz könnten mit angemessener Entlastungshilfe
    weiterhin in ihren eigenen vier Wänden verweilen und müssten nicht ihre gewohnte Umgebung verlassen.
    Für diesen Bedarf wurde 2017 der Pflegegrad 1 neu eingeführt.</p>

  <p>Jedem ab dem Pflegegrad 1 steht ein zweckgebundener ambulanter Entlastungsbetrag von
    125 Euro monatlich seitens der Pflegeversicherung (§45b SGB XI) zu.</p>

  <p>Das heißt bis zu einem Betrag von 125 Euro können die Entlastungsleistungen mit der Krankenkasse abgerechnet werden.
    Selbstverständlich bieten wir die Entlastungsleistungen auch als reine Privatleistung an.</p>

</div>

<div class="container2">
  <p>Für Fragen und weitere Informationen stehen wir Ihnen jederzeit gerne zur Verfügung! </p>
</div>

