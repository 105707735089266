<h2 mat-dialog-title>Impressum</h2>
<mat-dialog-content class="mat-typography">



  <div class="container">
    <div class="jumbotron">


      <div class="col-12">

        <h1>Datenschutzerklärung</h1>
        <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
        <p>Firma Gersprenz betreuung - Ihre Alltags und Seniorenbetreuung
          <br>Inhaber: Andrea Wanning
          <br>Erbacher Str. 86
          <br>64395 Brensbach
          <br>
          <br>
          <br>Telefon: 015140371778
          <br>www.gersprenz-betreuung.com
          <br>kontakt@gersprenz-betreuung.com
          <br>
          <br>
          <br>Umsatzsteuer ID: 49 018 267 551
          <br>Aufsichtsbehörde: Brensbach Odenwald</p>
        <h2>Ihre Betroffenenrechte</h2>
        <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
        <ul>
          <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
          <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
          <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
          <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
          <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
          <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
        </ul>
        <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>
        <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.</p>
        <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="nofollow noopener">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
        <h2>Kontaktformular</h2>
        <h3>Art und Zweck der Verarbeitung:</h3>
        <p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</p>
        <h3>Rechtsgrundlage:</h3>
        <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art 6 Abs. 1 lit. f DSGVO).</p>
        <p>Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.</p>
        <p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art 6 Abs. 1 lit. b DSGVO).</p>
        <h3>Empfänger:</h3>
        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
        <h3>Speicherdauer:</h3>
        <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
        <p>Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen. </p>
        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
        <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
        <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
        <h3>Art und Zweck der Verarbeitung:</h3>
        <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.</p>
        <p>Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></p>
        <h3>Rechtsgrundlage:</h3>
        <p>Rechtsgrundlage für die Einbindung von Google Webfonts und dem damit verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). </p>
        <h3>Empfänger:</h3>
        <p>Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken – dass der Betreiber in diesem Fall Google Daten erhebt.</p>
        <h3>Speicherdauer:</h3>
        <p>Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google Webfonts.</p>
        <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.</p>
        <h3>Drittlandtransfer:</h3>
        <p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen <a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</p>
        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
        <p>Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich, noch vertraglich vorgeschrieben. Allerdings kann ohne die korrekte Darstellung der Inhalte von Standardschriften nicht ermöglicht werden.</p>
        <h3>Widerruf der Einwilligung:</h3>
        <p>Zur Darstellung der Inhalte wird regelmäßig die Programmiersprache JavaScript verwendet. Sie können der Datenverarbeitung daher widersprechen, indem Sie die Ausführung von JavaScript in Ihrem Browser deaktivieren oder einen Einbindung JavaScript-Blocker installieren. Bitte beachten Sie, dass es hierdurch zu Funktionseinschränkungen auf der Website kommen kann.</p>
        <h2>Verwendung von Adobe Typekit</h2>
        <h3>Art und Zweck der Verarbeitung:</h3>
        <p>Wir setzen Adobe Typekit zur visuellen Gestaltung unserer Website ein. Typekit ist ein Dienst der Adobe Systems Software Ireland Companies (4-6 Riverwalk, Citywest Business Campus, Dublin 24, Republic of Ireland; nachfolgend „Adobe“), der uns den Zugriff auf eine Schriftartenbibliothek gewährt. Zur Einbindung der von uns benutzten Schriftarten, muss Ihr Browser eine Verbindung zu einem Server von Adobe in den USA aufbauen und die für unsere Website benötigte Schriftart herunterladen. Adobe erhält hierdurch die Information, dass von Ihrer IP-Adresse unsere Website aufgerufen wurde. Weitere Informationen zu Adobe Typekit finden Sie in den Datenschutzhinweisen von Adobe, die Sie hier abrufen können: <a href="https://www.adobe.com/de/privacy/policy.html" target="_blank">https://www.adobe.com/de/privacy/policy.html</a></p>
        <h3>Rechtsgrundlage:</h3>
        <p>Rechtsgrundlage für die Einbindung von Adobe Typekit und dem damit verbundenen Datentransfer zu Adobe ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</p>
        <h3>Empfänger:</h3>
        <p>Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum Betreiber der Bibliothek aus. Informationen über die Nutzung Ihrer Daten durch Adobe Typekit Web Fonts Sie unter <a href="https://typekit.com/">https://typekit.com/</a> und in der Datenschutzerklärung von Adobe Typekit: <a href="https://www.adobe.com/de/privacy/policies/typekit.html">https://www.adobe.com/de/privacy/policies/typekit.html</a>.</p>
        <h3>Speicherdauer:</h3>
        <p>Wir erheben keine personenbezogenen Daten durch die Einbindung von Adobe Typekit Web Fonts.</p>
        <h3>Drittlandtransfer:</h3>
        <p>Adobe ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000TNo9AAG&status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000TNo9AAG&status=Active</a>).</p>
        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
        <p>Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich, noch vertraglich vorgeschrieben. Allerdings kann ohne die korrekte Darstellung der Inhalte von Standardschriften nicht ermöglicht werden.</p>
        <h2>Verwendung von Google Maps</h2>
        <h3>Art und Zweck der Verarbeitung:</h3>
        <p>Auf dieser Webseite nutzen wir das Angebot von Google Maps. Google Maps wird von Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend „Google“) betrieben. Dadurch können wir Ihnen interaktive Karten direkt in der Webseite anzeigen und ermöglichen Ihnen die komfortable Nutzung der Karten-Funktion.</p>
        <p>Nähere Informationen über die Datenverarbeitung durch Google können Sie <a href="http://www.google.com/privacypolicy.html">den Google-Datenschutzhinweisen</a> entnehmen. Dort können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern.</p>
        <p>Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten<a href="http://www.dataliberation.org/"> finden Sie hier</a>.</p>
        <h3>Rechtsgrundlage:</h3>
        <p>Rechtsgrundlage für die Einbindung von Google Maps und dem damit verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</p>
        <h3>Empfänger:</h3>
        <p>Durch den Besuch der Webseite erhält Google Informationen, dass Sie die entsprechende Unterseite unserer Webseite aufgerufen haben. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob keine Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet.</p>
        <p>Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons bei Google ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechter Gestaltung seiner Webseite. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Webseite zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten müssen.</p>
        <h3>Speicherdauer:</h3>
        <p>Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google Maps.</p>
        <h3>Drittlandtransfer:</h3>
        <p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen <a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</p>
        <h3>Widerruf der Einwilligung:</h3>
        <p>Wenn Sie nicht möchten, dass Google über unseren Internetauftritt Daten über Sie erhebt, verarbeitet oder nutzt, können Sie in Ihrem Browsereinstellungen JavaScript deaktivieren. In diesem Fall können Sie unsere Webseite jedoch nicht oder nur eingeschränkt nutzen.</p>
        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
        <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu Funktionseinschränkungen auf der Website kommen.</p>
        <h2>Social Plugins</h2>
        <h3>Art und Zweck der Verarbeitung:</h3>
        <p>Auf unseren Webseiten werden Social Plugins der unten aufgeführten Anbieter eingesetzt. Die Plugins können Sie daran erkennen, dass sie mit dem entsprechenden Logo gekennzeichnet sind.</p>
        <p>Über diese Plugins werden unter Umständen Informationen, zu denen auch personenbezogene Daten gehören können, an den Dienstebetreiber gesendet und ggf. von diesem genutzt. Wir verhindern die unbewusste und ungewollte Erfassung und Übertragung von Daten an den Diensteanbieter durch eine 2-Klick-Lösung. Um ein gewünschtes Social Plugin zu aktivieren, muss dieses erst durch Klick auf den entsprechenden Schalter aktiviert werden. Erst durch diese Aktivierung des Plugins wird auch die Erfassung von Informationen und deren Übertragung an den Diensteanbieter ausgelöst. Wir erfassen selbst keine personenbezogenen Daten mittels der Social Plugins oder über deren Nutzung.</p>
        <p>Wir haben keinen Einfluss darauf, welche Daten ein aktiviertes Plugin erfasst und wie diese durch den Anbieter verwendet werden. Derzeit muss davon ausgegangen werden, dass eine direkte Verbindung zu den Diensten des Anbieters ausgebaut wird sowie mindestens die IP-Adresse und gerätebezogene Informationen erfasst und genutzt werden. Ebenfalls besteht die Möglichkeit, dass die Diensteanbieter versuchen, Cookies auf dem verwendeten Rechner zu speichern. Welche konkreten Daten hierbei erfasst und wie diese genutzt werden, entnehmen Sie bitte den Datenschutzhinweisen des jeweiligen Diensteanbieters. Hinweis: Falls Sie zeitgleich bei Facebook angemeldet sind, kann Facebook Sie als Besucher einer bestimmten Seite identifizieren.</p>
        <p>Wir haben auf unserer Website die Social-Media-Buttons folgender Unternehmen eingebunden:</p>
        <p>Facebook https://www.facebook.com/</p>
        <h2>Änderung unserer Datenschutzbestimmungen</h2>
        <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
        <h2>Fragen an den Datenschutzbeauftragten</h2>
        <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:</p>
        <p>Andrea Wanning
          <br>Erbacher Str. 86
          <br>64395 Brensbach
          <br>
          <br>
          <br>Telefon: 015140371778
          <br>kontakt@gersprenz-betreuung.com</p>
        <!-- <p><em>Die Datenschutzerklärung wurde mit dem </em><em><a href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/" target="_blank" rel="noopener">Datenschutzerklärungs-Generator der activeMind AG erstellt</a> (Version 2018-06-22).</em></p> -->

      </div>







    </div>
  </div>



</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close style=" cursor: pointer" (click)="closeCookieContent()">Okay</button>
</mat-dialog-actions>
