<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation">
    <mat-nav-list>
      <a mat-list-item (click)="sidenav.close()">
        <a class="navElements1" [href]="'tel:'+ location.phoneNumber">
          <i class="material-icons">phone</i>
          <span>{{location.phoneNumber}}</span>
        </a>
      </a>
      <a mat-list-item (click)="sidenav.close()">
        <a class="navElements2" [href]="'mailto:'+location.email">
          <i class="material-icons">email</i>
          <span>{{location.email}}</span>
        </a>
      </a>

      <a mat-list-item (click)="sidenav.close(); openImprint()">
        <a class="navElements2" >
          <i class="material-icons">notes</i>
          <span>Impressum</span>
        </a>
      </a>



      <!--<a mat-list-item (click)="sidenav.close()">
        <span class="nav-caption">Über uns</span>
      </a>
      <a mat-list-item routerLink="/invoice" (click)="sidenav.close()">
        <span class="nav-caption">Leistungen</span>
      </a>
      <mat-list-item (click)="sidenav.close()">
        <button mat-icon-button>
          <span class="nav-caption">Logout</span>
        </button>
      </mat-list-item>-->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <div fxHide.gt-sm>
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div class="navbarImage"><img src="../assets/images/logo_tmp.png"></div>
      <div class="navItems" fxFlex fxLayout fxLayoutAlign="flex-end">
        <ul fxLayout fxLayoutGap="10px" class="navigation-items">
          <li fxHide.xs fxHide.sm>
            <a class="navElements1" [href]="'tel:'+ location.phoneNumber">
              <i class="material-icons">phone</i>
              <span>{{location.phoneNumber}}</span>
            </a>
          </li>
          <li fxHide.xs fxHide.sm>
            <a class="navElements2" [href]="'mailto:'+location.email">
              <i class="material-icons">email</i>
              <span>{{location.email}}</span>
            </a>
          </li>
        </ul>
      </div>
    </mat-toolbar>
    <main class="main">
      <app-welcome></app-welcome>
      <app-leistungen></app-leistungen>
      <app-kontakt></app-kontakt>
      <div class="cookie-container" *ngIf="cookieContent === true">
        <a>Cookies helfen uns bei der Bereitstellung unserer Website. Durch die Nutzung der Website erklären Sie sich damit einverstanden, dass wir Cookies setzen.
          <a class="policy" (click)="privacyPolicy()">Datenschutz</a>
        </a>
        <button class="cookie-btn" mat-button (click)="closeCookieContent()">Okay</button>
      </div>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

<footer class="footer" fxflex style="flex: 1 1 0%; box-sizing: border-box;">
  <div class="footer-content">
    <a>© Gersprenz betreuung - Ihre Alltags und Seniorenbetreuung | <a
      style="color: #007db8; cursor: pointer" (click)="openImprint()">Impressum</a> | <a fxHide.xs
      style="color: #007db8; cursor: pointer" (click)="privacyPolicy()">Datenschutz</a></a>
  </div>

</footer>
