import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {DataService} from './data.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'website';
  cookieContent = true;
  location = {
    phoneNumber: '015140371778',
    email: 'kontakt@gersprenz-betreuung.com'
  };

  constructor(private dataService: DataService, @Inject(DOCUMENT) private document: Document, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.cookieContent = true;
    this.dataService.cookieContent.subscribe(change => {
      this.cookieContent = change;
    });
  }

  closeCookieContent() {
    this.cookieContent = false;
  }

  async openImprint() {
    this.dialog.open(ImprintComponent);
  }

  async privacyPolicy(){
    this.dialog.open(PrivacyComponent);
  }

}


@Component({
  selector: 'app-imprint',
  templateUrl: 'imprint.html',
})
export class ImprintComponent implements OnInit {
  cookieContent = true;

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    this.cookieContent = true;
  }

  closeCookieContent() {
    this.dataService.closeCookieContent(false);
  }
}

@Component({
  selector: 'app-privacy',
  templateUrl: 'privacy.html',
})
export class PrivacyComponent implements OnInit {
  cookieContent = true;

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    this.cookieContent = true;
  }

  closeCookieContent() {
    this.dataService.closeCookieContent(false);
  }
}
