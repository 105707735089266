import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';

@Injectable()
export class DataService{
  cookieContent = new Subject<boolean>();
  // hoppingCardElement = new Subject<Orders>();
  constructor(private router: Router) {
  }

  closeCookieContent(trueFalse){
    this.cookieContent.next(trueFalse);
  }
}
