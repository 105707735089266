<div class="container1">
  <h1>Unsere Leistungen </h1>
  <h2>Die Gersprenztaler Alltags- und Seniorenbetreuung bietet folgende auf Ihre Bedürfnisse zugeschnittene Entlastungsleistungen an:</h2>
</div>

<div class="container2">
  <div class="listElement1">
    <h2>
      Haushalt, z.B.:
    </h2>
    <p>
      o	Staubwischen<br>
      o	Fußböden saugen und wischen<br>
      o	Abstauben der Fußleisten<br>
      o	Abfallbeseitigung<br>
      o	Küche säubern<br>
      o	Badezimmer säubern<br>
      o	Kochen, Backen<br></p>
  </div>
  <div class="listElement2">
    <h2>
      Alltagsbegleitung, z.B.:
    </h2>
    <p>
      o	Botengänge<br>
      o	Einkäufe<br>
      o	Fahrdienste<br>
      o	Arztbesuche<br>
      o	Friedhofbesuche<br>
      o	Spazierengehen<br>
    </p>
  </div>
</div>


<div class="container2">
  <div class="listElement1">
    <h2>
      Haustierbetreuung, z.B.:
    </h2>
    <p>
      o	Füttern<br>
      o	Säubern<br>
      o	Spazierengehen<br>
    </p>
  </div>
  <div class="listElement2">
    <h2>
      Gartenarbeiten, z.B.:
    </h2>
    <p>
      o	Rasenpflege<br>
      o	Beetpflege<br>
      o	Unkraut entfernen<br>
      o	Bewässern<br>
    </p>
  </div>
</div>

<!--<div class="container3">
  <p>Unsere Leistungen der Hauswirtschaft können Sie selbstverständlich auch als reine Privatleistung von uns erhalten.
    Sprechen Sie uns an, wir erstellen Ihnen ein unverbindliches Angebot.</p>
</div>-->
